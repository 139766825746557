import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 365 259">
    <path
      stroke="#02BA44"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M160.414 119.131v121.131a17.844 17.844 0 005.358 12.469 18.008 18.008 0 0012.606 5.142c4.717 0 9.245-1.847 12.606-5.142a17.84 17.84 0 005.358-12.469V119.131M145.619 119.132c0-1.953.78-3.826 2.167-5.207a7.412 7.412 0 015.23-2.157c1.962 0 3.843.776 5.23 2.157a7.348 7.348 0 012.167 5.207"
    />
    <path
      stroke="#02BA44"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M196.341 119.132a7.334 7.334 0 01.512-2.873 7.395 7.395 0 014.014-4.093 7.434 7.434 0 015.746 0 7.395 7.395 0 014.014 4.093c.361.914.535 1.891.512 2.873"
    />
    <path
      stroke="#02BA44"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M374.155 120.164c-26.734 0-32.181-.315-68.422-12.769-26.409-9.077-58.67-15.453-58.67 11.736v121.131c0 4.742-1.893 9.29-5.261 12.644a18.007 18.007 0 01-12.701 5.237 18.003 18.003 0 01-12.701-5.237 17.84 17.84 0 01-5.261-12.644V119.131M.844.84c11.236 99.13 108.86 66.614 108.86 122.492v116.93a17.848 17.848 0 005.358 12.469 18.009 18.009 0 0012.607 5.142c4.717 0 9.245-1.847 12.606-5.142a17.848 17.848 0 005.358-12.469V119.131"
    />
    <path
      stroke="#02BA44"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M109.705 155.511a3.687 3.687 0 001.127-.236 17.078 17.078 0 001.612-.659c3.46-1.579 9.067-4.797 15.228-5.818 5.957-.982 12.43.093 17.962 6.721M196.342 180.352c-13.574 0-19.088-12.904-35.928 0M247.063 138.192c-13.57 0-19.089-12.903-35.924 0"
    />
    <path
      fill="#02BA44"
      d="M135.784 93.723a3.28 3.28 0 003.287-3.272 3.28 3.28 0 00-3.287-3.272 3.28 3.28 0 00-3.287 3.272 3.28 3.28 0 003.287 3.272zM232.269 58.46a3.28 3.28 0 003.287-3.271 3.28 3.28 0 00-3.287-3.272 3.28 3.28 0 00-3.287 3.272 3.28 3.28 0 003.287 3.272zM225.696 79.026a3.28 3.28 0 003.287-3.272 3.28 3.28 0 00-3.287-3.272 3.28 3.28 0 00-3.287 3.272 3.28 3.28 0 003.287 3.272zM235.557 88.843a3.28 3.28 0 003.287-3.273 3.28 3.28 0 00-3.287-3.272 3.28 3.28 0 00-3.287 3.272 3.28 3.28 0 003.287 3.273zM124.367 108.529a3.28 3.28 0 003.287-3.272 3.28 3.28 0 00-3.287-3.272 3.28 3.28 0 00-3.287 3.272 3.28 3.28 0 003.287 3.272zM187.219 202.253a3.28 3.28 0 003.287-3.272 3.28 3.28 0 00-3.287-3.273 3.28 3.28 0 00-3.287 3.273 3.28 3.28 0 003.287 3.272zM174.675 220.331a3.28 3.28 0 003.287-3.272 3.28 3.28 0 00-3.287-3.272 3.28 3.28 0 00-3.287 3.272 3.28 3.28 0 003.287 3.272zM183.933 231.269a3.28 3.28 0 003.287-3.272 3.28 3.28 0 00-3.287-3.272 3.28 3.28 0 00-3.287 3.272 3.28 3.28 0 003.287 3.272z"
    />
  </svg>
));
