import React from "react";
import styled, { css } from "styled-components";
import { Container, Row, Col, Visible } from "react-awesome-styled-grid";

import { paddingWidth, mediaquery, spacing } from "src/styles/variables";
import BubblesSm from "src/atoms/Vectors/Large/BubblesSm";
import BubblesLg from "src/atoms/Vectors/Large/BubblesLg";
import Copy from "./Copy";

const NotFound = (props) => (
  <Container>
    <RowStyled as="section">
      <Visible xs sm>
        <Col xs={8}>
          <NoMargin left right>
            <BubblesSmStyled size="100%" />
          </NoMargin>
        </Col>
      </Visible>
      <Col md={7} offset={{ md: 1 }}>
        <Copy {...props} />
      </Col>
      <Visible md lg xl>
        <Col md={8}>
          <NoMargin right>
            <BubblesLg size="100%" />
          </NoMargin>
        </Col>
      </Visible>
    </RowStyled>
  </Container>
);

NotFound.propTypes = Object.assign({}, Copy.propTypes);

const RowStyled = styled(Row)`
  margin: ${spacing.stack.xl};
`;

const BubblesSmStyled = styled(BubblesSm)`
  margin: ${spacing.stack.sm};
`;

const NoMargin = styled.div`
  margin-left: -${({ left }) => left && paddingWidth.md}rem;
  margin-right: -${({ right }) => right && paddingWidth.md}rem;

  ${mediaquery.md(css`
    margin-left: -${({ left }) => left && paddingWidth.md}rem;
    margin-right: -${({ right }) => right && paddingWidth.md}rem;
  `)}
  ${mediaquery.lg(css`
    margin-left: -${({ left }) => left && paddingWidth.lg}rem;
    margin-right: -${({ right }) => right && paddingWidth.lg}rem;
  `)}
  ${mediaquery.xl(css`
    margin-left: -${({ left }) => left && paddingWidth.xl}rem;
    margin-right: -${({ right }) => right && paddingWidth.xl}rem;
  `)}
`;

export default NotFound;
