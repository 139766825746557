import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 796 552">
    <path
      stroke="#02BA44"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M340.504 253.902v258.162a38.115 38.115 0 0011.373 26.576 38.15 38.15 0 0053.518 0 38.106 38.106 0 0011.373-26.576V253.902M309.103 253.902c0-4.163 1.654-8.154 4.599-11.097a15.704 15.704 0 0122.204 0 15.69 15.69 0 014.598 11.097M416.768 253.902a15.698 15.698 0 019.607-14.846 15.71 15.71 0 0112.196 0 15.704 15.704 0 019.607 14.846M794.209 256.104c-56.748 0-68.311-.672-145.237-27.215-56.058-19.345-124.539-32.935-124.539 25.013v258.162a38.101 38.101 0 01-11.167 26.948 38.137 38.137 0 01-53.921 0 38.101 38.101 0 01-11.167-26.948V253.902"
    />
    <path
      stroke="#02BA44"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M1.791 1.79c23.851 211.272 231.075 141.973 231.075 261.064v249.21a38.115 38.115 0 0011.373 26.576 38.15 38.15 0 0053.518 0 38.106 38.106 0 0011.373-26.576V253.902"
    />
    <path
      stroke="#02BA44"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M232.866 331.436a7.78 7.78 0 002.392-.501 36.45 36.45 0 003.421-1.405c7.344-3.366 19.247-10.224 32.324-12.399 12.646-2.095 26.385.197 38.127 14.323M416.768 384.379c-28.813 0-40.519-27.5-76.264 0M524.433 294.527c-28.804 0-40.519-27.502-76.255 0M288.226 199.75a6.975 6.975 0 006.977-6.974 6.976 6.976 0 00-6.977-6.974 6.976 6.976 0 00-6.977 6.974 6.975 6.975 0 006.977 6.974zM493.032 124.596a6.975 6.975 0 006.977-6.973 6.975 6.975 0 00-6.977-6.974 6.975 6.975 0 100 13.947zM479.078 168.426a6.975 6.975 0 006.977-6.974 6.975 6.975 0 00-6.977-6.973 6.975 6.975 0 100 13.947zM500.009 189.347a6.975 6.975 0 006.977-6.973 6.975 6.975 0 00-6.977-6.974 6.975 6.975 0 100 13.947zM263.99 231.306a6.975 6.975 0 006.977-6.973 6.975 6.975 0 00-6.977-6.974 6.975 6.975 0 00-6.977 6.974 6.975 6.975 0 006.977 6.973zM397.404 431.056a6.975 6.975 0 100-13.947 6.975 6.975 0 00-6.977 6.973 6.975 6.975 0 006.977 6.974zM370.777 469.586a6.975 6.975 0 006.977-6.973 6.975 6.975 0 00-6.977-6.974 6.975 6.975 0 00-6.977 6.974 6.975 6.975 0 006.977 6.973zM390.427 492.898a6.975 6.975 0 006.977-6.974 6.975 6.975 0 00-6.977-6.974 6.976 6.976 0 00-6.977 6.974 6.976 6.976 0 006.977 6.974z"
    />
  </svg>
));
