import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { colors, mediaquery, spacing } from "src/styles/variables";
import { LabelL, LabelS, HeaderM } from "src/atoms/Typography";
import Link, { TYPES } from "src/atoms/Link";

const Copy = ({ title, subtitle, firstSuggestion, secondSuggestion }) => (
  <Outer>
    <Title as="h1">{title}</Title>
    <Subtitle as="h2">{subtitle}</Subtitle>
    {!!firstSuggestion && (
      <FirstSuggestion as={Link} {...firstSuggestion} type={TYPES.button} />
    )}
    {!!secondSuggestion && (
      <SecondSuggestion as={Link} {...secondSuggestion} type={TYPES.link} />
    )}
  </Outer>
);

Copy.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  firstSuggestion: PropTypes.shape(Link.propTypes),
  secondSuggestion: PropTypes.shape(Link.propTypes),
};

const Outer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: ${spacing.stack.xl};

  ${mediaquery.md(css`
    align-items: flex-start;
  `)}
`;
const Title = styled(LabelL)`
  color: ${colors.activiaGreen};
  margin: ${spacing.stack.sm};
`;
const Subtitle = styled(HeaderM)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.sm};
  max-width: 420px;
`;
const FirstSuggestion = styled.div`
  margin: ${spacing.stack.md};
  width: 100%;

  ${mediaquery.md(css`
    width: auto;
  `)}
`;
const SecondSuggestion = styled(LabelS)`
  color: ${colors.darkGrey};
`;

export default Copy;
